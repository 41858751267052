import polyglot from 'services/localization';
import React from 'react';
import { getMatchingMileageString } from 'shared-library/src/services/vehicleService';
import { roundDigits } from 'shared-library/src/services/invoiceCalculationService';
import {
  faFile, faWrench, faFileSlash, faListUl,
} from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { INVOICE_STATUSES } from 'shared-library/src/definitions/invoice';
import { INVOICE_DUNNING_STATUSES } from 'constants';

export const STATUSES = [
  {
    key: 'draft',
    label: polyglot.t('invoices.statuses.draft', 1),
  },
  {
    key: 'preview',
    label: polyglot.t('invoices.statuses.preview', 1),
  },
  {
    key: 'open',
    label: polyglot.t('invoices.statuses.invoice', 1),
  },
];

export function getDifference(a, b) {
  return roundDigits(a - b, 2);
}

export function getBillingAddress(billingAddress = {}) {
  const {
    formOfAddress,
    title,
    toAttention,
    firstName,
    lastName,
    address,
    zipCode,
    city,
    country,
    UIDNumber,
  } = billingAddress;

  // if (!billingAddress.address) return '';

  const localizedFormOfAddress = formOfAddress
    ? polyglot.t(`editClient.formOfAddressOptions.${formOfAddress}`)
    : '';

  const parts = [
    `${localizedFormOfAddress} ${lastName || ''} ${firstName || ''}`,
    title || '',
    address || '',
    `${zipCode || ''} ${city || ''}`,
    country || '',
    UIDNumber || '',
    toAttention || '',
  ];

  return parts.filter((part) => part.trim()).join('; ');
}

export function getVehicleDisplayString(vehicle, date) {
  const parts = [];

  if (!vehicle || !date) return '';

  if (vehicle.license) parts.push(vehicle.license.trim());
  parts.push(vehicle.model.trim());

  const matchingMileage = getMatchingMileageString(vehicle.mileage, date);
  if (matchingMileage) parts.push(matchingMileage);

  return parts.join(' | ');
}

export const getIsAutogeneratedNegative = (invoice) => {
  return invoice.dueDate === undefined && invoice.gross < 0;
};

export function syncTax(item, isVatIncluded) {
  const { positionPrice, vat } = item;
  return {
    ...item,
    tax: positionPrice
    && isVatIncluded
      ? roundDigits(positionPrice - positionPrice / (1 + vat.rate / 100), 2)
      : roundDigits(positionPrice * (vat.rate / 100), 2),
  };
}

export const syncPositionPrice = (item, isVatIncluded) => {
  const { price, quantity } = item;
  return syncTax({
    ...item,
    positionPrice: ![price, quantity].includes(null) ? roundDigits(price * quantity, 2) : null,
  }, isVatIncluded);
};

export const syncPrice = (item) => {
  const { unitPrice, discount } = item;
  const newDiscount = discount !== null ? discount : 0;

  return {
    ...item,
    price: unitPrice !== null ? roundDigits(unitPrice - unitPrice * (newDiscount / 100), 2) : null,
  };
};

export const syncDiscount = (item) => {
  const { price, unitPrice } = item;
  return {
    ...item,
    discount: ![price, unitPrice].includes(null)
      ? Math.round(unitPrice * 100 - price * 100) / unitPrice
      : null,
  };
};

export function createNewInvoiceItem(item) {
  return syncTax(syncPositionPrice(syncPrice(item)));
}

/**
 * Get groupindex of groupItem
 * @param {groupItem} item { index: ".." }
 * @returns index of group (for instance 1003 -> 1, 4001 -> 4, 10021 -> 10)
 */
export function getItemGroupIndex(item) {
  if (item?.index == null) return null;
  if (item.index.toString().indexOf('.*') >= 0) return parseInt(item.index.toString().substring(0, item.index.toString().indexOf('.')), 10);
  if (item.index > 1000) return (item.index - (item.index % 1000)) / 1000;
  return null;
}

/**
 * Get internalIndex of groupItem
 * @param {groupItem} item { index: ".." }
 * @param {groupItem[]?} items optional items (needed for "star" positions like 3.* or 10.*)
 * @returns internal Index for groupItem (1003 => 3, 4001 -> 1, 10021 -> 21)
 */
export function getItemIndex(item, items) {
  if (item?.index == null) return item?.index;
  if (item.index.toString().indexOf('.*') >= 0) return items ? items[getItemGroupIndex(item) - 1].items.length : item.index;
  if (item.index > 1000) return item.index % 1000;
  return item.index;
}

export function isGroupItem(item) {
  if (item?.index == null) return false;
  return item.index > 1000 || item.index.toString().indexOf('.*') >= 0;
}

export function getParsedUnit(unit) {
  return polyglot.has(`units.${unit}`) ? polyglot.t(`units.${unit}`) : unit;
}

export function getNextDunningStatus(dunningStatus) {
  const dunningStatusIndex = INVOICE_DUNNING_STATUSES.indexOf(dunningStatus);
  const nextDunningStatusIndex = dunningStatusIndex + 1 < INVOICE_DUNNING_STATUSES.length
    ? dunningStatusIndex + 1
    : dunningStatusIndex;

  return INVOICE_DUNNING_STATUSES[nextDunningStatusIndex];
}

export function getInvoiceIconByStatus(status, flipped = false) {
  const faFileInvoiceDollar = <i className={'fak fa-euro'} />;
  const faOffer = <i style={{ transform: 'scaleX(-1)' }} className={'fak fa-offer'} />;
  const style = { verticalAlign: 'bottom' };
  if (flipped) {
    style.transform = 'scaleX(-1)';
  }
  if (!status) {
    style.transform = 'unset';
  }
  let image = '';
  switch (status) {
    case 'invoice':
    case 'overdue':
    case 'paidOut':
    case 'reminder':
    case 'demandLetter1':
    case 'demandLetter2':
    case 'demandLetter3':
    case INVOICE_STATUSES.PAID:
    case INVOICE_STATUSES.OPEN:
      image = faFileInvoiceDollar;
      break;
    case INVOICE_STATUSES.PREVIEW:
      image = faFile;
      break;
    case INVOICE_STATUSES.DRAFT:
    case INVOICE_STATUSES.INTERNAL:
      image = faWrench;
      break;
    case INVOICE_STATUSES.PAID_CREDIT:
      image = faFileSlash;
      break;
    case 'offer':
      image = faOffer;
      break;
    case 'active':
      image = faOffer;
      break;
    case 'costListing':
      image = faListUl;
      break;
    default:
      image = faFile;
  }
  if (!status) {
    image = faFile;
  }
  return (
    <>
      {image.$$typeof ? (
        <>
          <div style={style}>
            {image}
          </div>
        </>
      ) : (
        <FontAwesomeIcon
           style={style}
           icon={image}
         />
      )}
    </>
  );
}

export function checkIfInvoiceHasAllRequiredFieldsForFatturaPa(garage, invoice) {
  const missingFields = [];

  if (!garage?.uid) missingFields.push('invoices.fatturaPa.missingFields.garageVatNumber');
  if (!garage?.address) missingFields.push('invoices.fatturaPa.missingFields.garageAddress');
  if (!garage?.zip) missingFields.push('invoices.fatturaPa.missingFields.garageZipCode');
  if (!garage?.city) missingFields.push('invoices.fatturaPa.missingFields.garageCity');
  if (!invoice?.client?.lastName) missingFields.push('invoices.fatturaPa.missingFields.clientName');
  if (!invoice?.client?.address) missingFields.push('invoices.fatturaPa.missingFields.clientAddress');
  if (!invoice?.client?.zipCode) missingFields.push('invoices.fatturaPa.missingFields.clientZipCode');
  if (!invoice?.client?.city) missingFields.push('invoices.fatturaPa.missingFields.clientCity');
  if (!invoice?.client?.UIDNumber && !invoice?.client?.taxNumber && invoice?.client?.country === 'IT') missingFields.push('invoices.fatturaPa.missingFields.clientTaxNumber');

  if (missingFields.length > 0) {
    return <div>
      {polyglot.t('invoices.fatturaPaXmlDownload.missingFields')}
      <br />
      <b>
        -
        {' '}
        {missingFields.map((key) => polyglot.t(key)).join('\n- ')}
      </b>
    </div>;
  }
  return false;
}

export function getInvoiceLabelByStatus(invoice) {
  let invoiceLabel = '';
  if (invoice.status === 'draft') {
    invoiceLabel = `${polyglot.t('invoices.draft')} ${invoice.draftNumber || ''}`;
  } else if (invoice.status === 'preview') {
    invoiceLabel = `${polyglot.t('invoices.preview')} ${invoice.draftNumber || ''}`;
  } else {
    invoiceLabel = `${polyglot.t('invoices.invoice')} ${invoice.number || ''}`;
  }
  return invoiceLabel;
}

export function getPdfsForDocument(document) {
  let allPdfs = {};
  if (document?.pdfs) allPdfs = { ...document.pdfs };
  if (document?.pdf) allPdfs = { ...allPdfs, offer: document.pdf };
  return allPdfs;
}
