import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faPhone, faEnvelope } from 'fontawesome/pro-solid-svg-icons';
import { PHONE_NUMBERS } from 'constants';

import InputModern from 'components/common/inputModern';
import ButtonModern from 'components/common/buttonModern';
import DropdownModern from 'components/common/dropdownModern';
import CheckboxModern from 'components/common/checkboxModern';
import Icon from 'components/common/icon/Icon';
import Logo from 'components/common/logo';
import { signup, validateEmail } from 'resources/account/account.api';
import polyglot, { getLocaleCountry } from 'services/localization';

import { FormValidatorGroup, FormValidator, Validators } from 'helpers/formValidation';

import { track } from 'services/tracking.service';
import accessStyles from '../../access.styles.pcss';
import BackgroundBlob from '../backgroundBlob';

import styles from './signup.styles.pcss';

const phoneService = require('shared-library/src/services/phoneNumberService').getInstance();

class Signup extends Component {
  constructor(props) {
    super(props);
    const initialSigninForm = this.generateInitialSignupForm();
    const secondSigninForm = this.generateSecondSignupForm();
    const { pathname } = window.location;
    const pathnameLinkPrefix = '/access/signup/link/';
    let link = '';
    if (pathname.startsWith(pathnameLinkPrefix)) {
      link = pathname.substring(pathnameLinkPrefix.length);
      secondSigninForm.group.recruitedBy.value = 'link';
      secondSigninForm.group.recruitedByAdditionalInfo.value = link;
    }
    this.state = {
      initialSigninForm,
      secondSigninForm,
      currentForm: 'initial',
      link,
    };
  }

  images = [
    'david',
    'magret',
    'thomas',
    'tanja',
    'lydia',
    'andreas',
    'marvin',
  ];

  countryValues = [
    { key: '', label: polyglot.t('access.signup.select') },
    { key: 'AT', label: polyglot.t('access.signup.austria') },
    { key: 'DE', label: polyglot.t('access.signup.germany') },
    { key: 'CH', label: polyglot.t('access.signup.switzerland') },
    { key: 'IT', label: polyglot.t('access.signup.italy') },
    { key: 'LI', label: 'Liechtenstein' },
    { key: 'LU', label: polyglot.t('access.signup.luxembourg') },
  ];

  recruitedByValues = [
    { key: '', label: polyglot.t('access.signup.select') },
    { key: 'internet', label: polyglot.t('access.signup.websearch') },
    { key: 'facebook', label: 'Facebook' },
    { key: 'instagram', label: 'Instagram' },
    { key: 'recommendation', label: polyglot.t('access.signup.recommendation') },
    { key: 'flyer', label: polyglot.t('access.signup.leaflet') },
    { key: 'gingerbread', label: polyglot.t('access.signup.gingerbread') },
    { key: 'forum', label: 'Forum' },
    { key: 'magazine', label: polyglot.t('access.signup.magazine') },
    { key: 'fair', label: polyglot.t('access.signup.fair') },
    { key: 'email', label: polyglot.t('access.signup.mail') },
    { key: 'other', label: polyglot.t('access.signup.other') },
  ];

  componentDidMount() {
    track({ event: 'User started registration', identifyUser: false });
  }

  generateInitialSignupForm = () => {
    const maxLen = 200;
    const formValidatorGroup = new FormValidatorGroup({
      firstName: new FormValidator('', [Validators.required(polyglot.t('users.errors.firstName')), Validators.maxLength(maxLen, polyglot.t('users.errors.firstName'))]),
      lastName: new FormValidator('', [Validators.required(polyglot.t('users.errors.lastName')), Validators.maxLength(maxLen, polyglot.t('users.errors.lastName'))]),
      email: new FormValidator('', [Validators.required(polyglot.t('users.errors.email')), Validators.email(polyglot.t('users.errors.email'))]),
    }, this, {}, this.onInitialFormSubmit, this.onValidateEmail);

    return formValidatorGroup;
  };

  generateSecondSignupForm = () => {
    const maxLen = 200;
    const country = this.countryValues.find((c) => c.key === getLocaleCountry())?.key || '';
    const formValidatorGroup = new FormValidatorGroup({
      title: new FormValidator('', [Validators.required(polyglot.t('garages.errors.title')), Validators.maxLength(maxLen, polyglot.t('garages.errors.title'))]),
      phone: new FormValidator('', [Validators.required(polyglot.t('users.errors.phone')), Validators.maxLength(maxLen, polyglot.t('users.errors.phone'))]),
      country: new FormValidator('', [Validators.required(polyglot.t('garages.errors.country'))]),
      recruitedBy: new FormValidator('', [Validators.required(polyglot.t('garages.errors.recruitedBy'))]),
      recruitedByAdditionalInfo: new FormValidator(''),
      acceptTerms: new FormValidator(false, [Validators.required(polyglot.t('access.signup.acceptTerms'))]),
      acceptPrivacy: new FormValidator(false, [Validators.required(polyglot.t('access.signup.acceptPrivacy'))]),
    }, this, { country }, this.submitSignup);

    return formValidatorGroup;
  };

  onValidateEmail = async (form) => {
    const email = form.group.email.value;
    try {
      await validateEmail({ email });
    } catch (x) {
      form.parseError(x);
    }
  };

  onInitialFormSubmit = async (values) => {
    track({
      event: 'User Signup initial Form complete',
      identifyUser: false,
      properties: {
        email: values.email,
      },
    });
    this.setState({ currentForm: 'second' });
  };

  submitSignup = async (values) => {
    const { initialSigninForm } = this.state;
    track({ event: 'User Signup complete', identifyUser: false });
    const requestObject = { ...values, ...initialSigninForm.getValues() };
    if (values.recruitedByAdditionalInfo) requestObject.recruitedBy += `: ${values.recruitedByAdditionalInfo}`;
    delete requestObject.recruitedByAdditionalInfo;

    await signup(requestObject);

    window.location = `${window.config.webUrl}?emailVerification=true&initialRoute=/welcome`;
  };

  renderContent = () => {
    const {
      initialSigninForm, secondSigninForm, currentForm, link,
    } = this.state;

    if (currentForm === 'second') {
      return (
        <secondSigninForm.Form>
          <div className={accessStyles.layoutContentBody}>
            <div
              className={cx(accessStyles.layoutContentBodyMessage, styles.signupHeaderContainer)}
            >
              <div>
                {polyglot.t('access.signup.testSevenDays')}
              </div>
              <div className={styles.signupHeaderText}>
                {polyglot.t('access.signup.testSevenDaysInfo')}
              </div>
            </div>
            <InputModern
              formValidator={secondSigninForm.group.title}
              label={polyglot.t('garages.title')}
              key="title"
              autoFocus
              dataTest="signupTitle"
            />
            <DropdownModern
              formValidator={secondSigninForm.group.country}
              label={polyglot.t('garages.country')}
              items={this.countryValues}
              key="country"
              dataTest="signupCountry"
            />
            <InputModern
              formValidator={secondSigninForm.group.phone}
              label={polyglot.t('users.phone')}
              key="phone"
              onBlur={(e) => {
                const phone = e.target.value;
                const country = secondSigninForm.group.country.value || getLocaleCountry();
                const formattedPhone = phoneService.formatNumberUsingCountryCode(phone, country);
                secondSigninForm.group.phone.set(formattedPhone || phone);
              }}
              dataTest="signupPhone"
            />
            {!link && (
              <DropdownModern
                formValidator={secondSigninForm.group.recruitedBy}
                label={polyglot.t('users.recruitedBy')}
                items={this.recruitedByValues}
                key="recruitedBy"
                dataTest="signupRecruitedBy"
              />
            )}
            {!link && secondSigninForm.group.recruitedBy.value && (
              <InputModern
                formValidator={secondSigninForm.group.recruitedByAdditionalInfo}
                label={polyglot.t('clientDetails.additionalInfo')}
                key="recruitedByAdditionalInfo"
                dataTest="signupRecruitedByAdditionalInfo"
              />
            )}
            <div className={styles.signupCheckboxContainer}>
              <CheckboxModern
                wrapperClassName={styles.signupCheckbox}
                formValidator={secondSigninForm.group.acceptTerms}
                dataTest="signupAcceptTerms"
              >
                <span>
                  {polyglot.t('access.signup.iAccept')}
                  <a className={cx(accessStyles.layoutLink, styles.signinSignupLink)} onClick={(e) => e.stopPropagation()} target="_blank" href="https://easywerkstatt.com/agb-easywerkstatt/" rel="noreferrer">
                    {polyglot.t('access.signup.terms')}
                  </a>
                </span>
              </CheckboxModern>
              <CheckboxModern
                wrapperClassName={styles.signupCheckbox}
                formValidator={secondSigninForm.group.acceptPrivacy}
                dataTest="signupAcceptPrivacy"
              >
                <span>
                  {polyglot.t('access.signup.iAccept')}
                  <a
                    className={cx(accessStyles.layoutLink, styles.signinSignupLink)}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://easywerkstatt.com/datenschutz/"
                  >
                    {polyglot.t('access.signup.privacy')}
                  </a>
                </span>
              </CheckboxModern>
            </div>
            <ButtonModern
              styleType="highlight"
              type="submit"
              isLoading={secondSigninForm.isSubmitting}
              dataTest="signupSubmitButton"
            >
              {polyglot.t('access.signup.testSevenDaysFree')}
            </ButtonModern>
            <div>
              <div className={accessStyles.layoutContentBodyBottomText}>
                {polyglot.t('access.alreadyGotAnAccount')}
              </div>
              <Link data-test="signupCancelButton" className={cx(accessStyles.layoutLink, styles.signinSignupLink)} to="/access/signin">
                {polyglot.t('access.loginHere')}
              </Link>
            </div>
          </div>
        </secondSigninForm.Form>
      );
    }

    return (
      <initialSigninForm.Form>
        <div className={accessStyles.layoutContentBody}>
          <div className={cx(accessStyles.layoutContentBodyMessage, styles.signupHeaderContainer)}>
            <div>
              {polyglot.t('access.signup.testSevenDays')}
            </div>
            <div className={styles.signupHeaderText}>
              {polyglot.t('access.signup.testSevenDaysInfo')}
            </div>
          </div>
          <InputModern
            formValidator={initialSigninForm.group.firstName}
            label={polyglot.t('users.firstName')}
            autoFocus
            name="first-name"
            dataTest="signupFirstName"
          />
          <InputModern
            formValidator={initialSigninForm.group.lastName}
            label={polyglot.t('users.lastName')}
            name="last-name"
            dataTest="signupLastName"
          />
          <InputModern
            formValidator={initialSigninForm.group.email}
            label={polyglot.t('users.email')}
            onBlur={() => this.onValidateEmail(initialSigninForm)}
            name="email"
            dataTest="signupEmail"
          />
          <ButtonModern
            styleType="highlight"
            type="submit"
            isLoading={initialSigninForm.isSubmitting}
            dataTest="signupSubmitButton"
          >
            {polyglot.t('access.signup.signupNow')}
          </ButtonModern>
          <div>
            <div className={accessStyles.layoutContentBodyBottomText}>
              {polyglot.t('access.alreadyGotAnAccount')}
            </div>
            <Link data-test="signupCancelButton" className={cx(accessStyles.layoutLink, styles.signinSignupLink)} to="/access/signin">
              {polyglot.t('access.loginHere')}
            </Link>
          </div>
        </div>
      </initialSigninForm.Form>
    );
  };

  render() {
    return (
      <div className={accessStyles.layout}>
        <BackgroundBlob />
        <div className={accessStyles.layoutContainer}>
          <div className={accessStyles.layoutContent}>
            <div className={accessStyles.layoutContentHeader}>
              <Logo className={accessStyles.layoutContentHeaderLogo} />
            </div>
            {this.renderContent()}
          </div>
          <Icon type="socialPage" className={styles.signupSocialPage} />
        </div>
        <div className={styles.signupFooter}>
          <div className={styles.signupFooterQuestions}>
            <div className={styles.signupFooterQuestionsTitle}>
              {polyglot.t('access.signup.gotQuestions')}
            </div>
            <div>
              {polyglot.t('access.signup.questionsInfo')}
            </div>
          </div>
          <div className={styles.signupFooterImageContainer}>
            {this.images.map((image, index) => {
              return (
                <img key={index} className={styles.signupFooterImage} src={`/assets/employees/${image}.png`} alt="" />
              );
            })}
          </div>
          <div className={styles.signupFooterContact}>
            <div className={styles.signupFooterContactItem}>
              <FontAwesomeIcon icon={faPhone} />
              <div>
                { PHONE_NUMBERS[getLocaleCountry()] || PHONE_NUMBERS.default }
              </div>
            </div>
            <div className={styles.signupFooterContactItem}>
              <FontAwesomeIcon icon={faEnvelope} />
              <div>
                {polyglot.t('com.supportEmailAddress')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
