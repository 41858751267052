import moment from 'moment';

import {
  downloadFile,
  downloadZipFile,
} from 'helpers/file';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import { fetchEmails } from 'resources/email/email.api';
import { INVOICE_EVENTS } from 'shared-library/src/definitions/invoiceEvents';
import polyglot from 'services/localization';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import * as api from './invoice.api';

export const FETCH_INVOICES = 'fetchInvoices';
export const FETCH_INVOICES_PRODUCT_HISTORY = 'fetchInvoicesProductHistory';
export const FETCH_INVOICES_OF_CLIENT = 'fetchInvoicesOfClient';
export const FETCH_INVOICES_OF_VEHICLE = 'fetchInvoicesOfVehicle';
export const FETCH_SALES_REPORT = 'fetchSalesReport';
export const FETCH_SALES_REPORT_BY_CLIENT = 'fetchSalesReportByClient';
export const FETCH_SALES_REPORT_BY_TYPE = 'fetchSalesReportByType';
export const FETCH_INVOICE = 'fetchInvoice';
export const FETCH_INVOICE_EMAIL = 'fetchInvoiceEmail';
export const UPDATE_INVOICE = 'updateInvoice';
export const UPDATE_SDI_STATUS_SOCKET = 'updateSdiStatusSocket';
export const UPDATE_INVOICE_SOCKET = 'updateInvoiceSocket';
export const CANCEL_INVOICE = 'cancelInvoice';
export const REMOVE_INVOICES = 'removeInvoices';
export const CREATE_INVOICE = 'createInvoice';
export const CHANGE_INVOICES_FILTER = 'changeInvoicesFilter';
export const RESET_INVOICES_STATE = 'resetInvoicesState';
export const PRINT_INVOICE = 'printInvoice';
export const EDIT_INVOICE = 'editInvoice';
export const CHANGE_CURRENT_INTEGRATION = 'changeCurrentIntegration';
export const FETCH_INVOICES_OF_CUSTOMER = 'fetchInvoicesOfCustomer';
export const FETCH_INVOICE_COUNT_BY_STATUS = 'fetchInvoiceCountByStatus';
export const RESTORE_INVOICE = 'restoreInvoice';
export const SEND_MESSAGE = 'sendMessage';

const getValidInvoiceData = (data) => {
  const invoice = {
    ...data,
    invoiceDate: data.invoiceDate ? moment.utc(data.invoiceDate) : null,
    dueDate: data.dueDate ? moment.utc(data.dueDate) : null,
    taxSums: data.taxSums.map((sum) => ({ ...sum, rate: +sum.rate })),
  };

  return invoice;
};

export const changeCurrentIntegration
  = (integration) => ({ type: CHANGE_CURRENT_INTEGRATION, payload: integration });

export const fetchInvoicesOfCustomer = (customerId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesOfCustomer(customerId, garageId);
};

export const fetchInvoices = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoices(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES, data, payload }));
};

export const fetchInvoicesProductHistory = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.fetchInvoicesProductHistory(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES_PRODUCT_HISTORY, data, payload }));
};

export const fetchInvoicesOfClient = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.fetchInvoicesOfClient(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES_OF_CLIENT, data, payload }));
};

export const fetchInvoicesOfVehicle = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.fetchInvoicesOfVehicle(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES_OF_VEHICLE, data, payload }));
};

export const fetchInvoiceCountByStatus = (options = {}) => (dispatch, getState) => {
  const data = options;
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoiceCountByStatus(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICE_COUNT_BY_STATUS, data, payload }));
};

export const fetchInvoice = (invoiceId, options = {}, data = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.fetchInvoice(garageId, invoiceId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICE, payload, options }));
};

export const fetchInvoiceEmail = (invoiceId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return fetchEmails(garageId, {
    invoiceId,
    pageSize: 1,
    sortBy: ['createdOn'],
    sortDirection: -1,
  })
    .then(({ results: [payload] }) => dispatch({ type: FETCH_INVOICE_EMAIL, payload }));
};

export const selectInvoice = (invoice) => ({ type: FETCH_INVOICE, payload: invoice });

export const getInvoicesCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const filename = `${data.status === 'costListing' ? 'costListings' : 'invoices'}${data.type === 'EXCEL' ? '.xlsx' : '.csv'}`;

  return api.getInvoicesCSV(garageId, data)
    .then((payload) => downloadFile(payload, filename));
};

export const getInvoicesExcel = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const filename = `${data.status === 'costListing' ? 'costListings' : 'invoices'}.xlsx`;

  return api.getInvoicesExcel(garageId, data)
    .then((payload) => downloadFile(payload, filename));
};

export const getReport = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getReport(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES, data, payload }));
};

export const getEarnings = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getEarnings(garageId, data);
};

export const changeReportFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getReport(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const getSalesReport = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getSalesReport(garageId, data)
    .then((payload) => dispatch({ type: FETCH_SALES_REPORT, payload }));
};

export const getRevenueReportByClient = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getRevenueReportByClient(garageId, data)
    .then((payload) => dispatch({ type: FETCH_SALES_REPORT_BY_CLIENT, payload }));
};

export const getRevenueReportByType = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getRevenueReportByType(garageId, data)
    .then((payload) => dispatch({ type: FETCH_SALES_REPORT_BY_TYPE, payload }));
};

export const getSalesReportCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getSalesReportCSV(garageId, data)
    .then((payload) => downloadFile(payload, 'sales.csv'));
};

export const getDiscounts = (sku, clientId = null) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getDiscounts(garageId, sku, clientId);
};

export const getOpenAmount = (data = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const canSeeInvoicePrice = fromUser.can(state, 'seeInvoicePrice');

  return canSeeInvoicePrice
    ? api.getOpenAmount(garageId, data)
    : Promise.resolve({ totalAmount: 0 });
};

export const getTurnover = (data = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getTurnover(garageId, data);
};

export const getReportCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getReportCSV(garageId, data)
    .then((payload) => {
      let fileName;
      if (data.type === 'EXCEL') {
        fileName = `rechnungen${data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
        }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''
        }.xlsx`;
      } else if (data.type === 'EU-FIBU') {
        fileName = 'BUBE.txt';
      } else if (data.type === 'DATEV') {
        fileName = 'EXTF_invoices.csv';
      } else {
        fileName = `rechnungen${data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
        }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''
        }.csv`;
      }

      downloadFile(payload, fileName);
    });
};

export const getInvoicePDFs = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getInvoicePDFNamesAndURL(garageId, data)
    .then((payload) => {
      downloadZipFile(polyglot.t('reports.invoices'), payload);
    }).catch((error) => {
      toastService.showError(parseError(error));
    });
};

export const getReportAndLinkedPdfZip = (data, buildLinkedCSV) => async (dispatch, getState) => {
  try {
    const garageId = fromUser.getCurrentGarageId(getState());
    const bmdCsv = await api.getReportCSV(garageId, data);
    const invoiceList = await api.getInvoicePDFNamesAndURL(garageId, data);
    const linkedCsvAsPdfBlob = buildLinkedCSV(bmdCsv, invoiceList);
    downloadZipFile(polyglot.t('reports.invoices'), invoiceList, linkedCsvAsPdfBlob);
  } catch (error) {
    toastService.showError(parseError(error));
  }
};

export const getEarningsCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const fileName = data.type === 'DATEV' ? 'EXTF_payments.csv' : 'payments.csv';

  return api.getEarningsCSV(garageId, data)
    .then((payload) => downloadFile(payload, fileName));
};

export const uploadInvoiceImage = (invoiceId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.uploadInvoiceImage(currentGarageId, invoiceId, data)
    .then((payload) => dispatch({
      type: UPDATE_INVOICE,
      invoiceId,
      data,
      payload,
    }));
};

export const removeInvoiceImages = (clientId, filesToRemove) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.removeInvoiceImages(currentGarageId, clientId, filesToRemove)
    .then((payload) => dispatch({
      type: UPDATE_INVOICE,
      clientId,
      filesToRemove,
      payload,
    }));
};

export const updateInvoiceImage = (clientId, imageId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.updateInvoiceImage(currentGarageId, clientId, imageId, data)
    .then((payload) => dispatch({
      type: UPDATE_INVOICE,
      clientId,
      imageId,
      data,
      payload,
    }));
};

export const createInvoice = (data, keepCurrentInvoice = false) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  const invoice = getValidInvoiceData(data);

  return api.createInvoice(garageId, invoice)
    .then((payload) => dispatch({ type: CREATE_INVOICE, payload, keepCurrentInvoice }));
};

export const updateInvoice
  = (invoiceId, data, keepCurrentInvoice = false) => (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());
    const invoice = getValidInvoiceData(data);

    return api.updateInvoice(garageId, invoiceId, invoice)
      .then((payload) => dispatch({ type: UPDATE_INVOICE, payload, keepCurrentInvoice }));
  };

export const changeStatus
  = (invoiceId, data, keepCurrentInvoice = false) => (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());
    const validData = {
      ...data,
      invoiceDate: data.invoiceDate ? moment.utc(data.invoiceDate) : null,
      dueDate: data.dueDate ? moment.utc(data.dueDate) : null,
    };

    return api.changeStatus(garageId, invoiceId, validData)
      .then((payload) => dispatch({ type: UPDATE_INVOICE, payload, keepCurrentInvoice }));
  };

export const convertToCostListing
  = (invoiceId, data, keepCurrentInvoice = false) => (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api.convertToCostListing(garageId, invoiceId, data)
      .then((payload) => dispatch({ type: UPDATE_INVOICE, payload, keepCurrentInvoice }));
  };

export const reopenInvoice
  = (invoiceId, keepCurrentInvoice = false) => (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api.reopenInvoice(garageId, invoiceId)
      .then((payload) => dispatch({ type: UPDATE_INVOICE, payload, keepCurrentInvoice }));
  };

export const cancelInvoice = (invoiceId, mode) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.cancelInvoice(garageId, invoiceId, { mode, userId })
    .then((payload) => dispatch({ type: CANCEL_INVOICE, payload }));
};

export const removeInvoices = (invoiceIds) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.removeInvoices(garageId, invoiceIds)
    .then((payload) => dispatch({ type: REMOVE_INVOICES, invoiceIds }));
};

export const addPayment = (invoiceId, data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.addPayment(invoiceId, garageId, data)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const cancelPayment = (invoiceId, paymentId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.cancelPayment(invoiceId, paymentId, garageId)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const sendInvoiceViaEmail = (invoiceId, data) => (dispatch, getState) => {
  return api.sendEmail(invoiceId, fromUser.getCurrentGarageId(getState()), data)
    .then((payload) => dispatch({ type: FETCH_INVOICE_EMAIL, payload }));
};

export const sendPickupNotificationViaSMS = (invoiceId, data) => (dispatch, getState) => {
  return api.sendSMS(fromUser.getCurrentGarageId(getState()), invoiceId, data)
    .then((payload) => dispatch({ type: SEND_MESSAGE, payload }));
};

export const changeInvoicesFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoices(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const changeInvoicesFilterCalendarModal = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesCalendarModal(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const changeInvoicesFilterOfClient = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesOfClient(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const changeInvoicesFilterOfVehicle = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesOfVehicle(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const resetInvoicesState = () => ({ type: RESET_INVOICES_STATE });

export const printInvoice = (id, status) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.printInvoice(garageId, id, status)
    .then((payload) => dispatch({ type: PRINT_INVOICE, id, payload }));
};

export const generateInvoicePdf = (id, status) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.generateInvoicePdf(garageId, id, status);
};

export const generateInvoiceReminderPdf = (id, dunningStatus) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.generateInvoiceReminderPdf(garageId, id, dunningStatus)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const getInvoicePdfUrl = (id, status, printVersion = false) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getInvoicePdfUrl(id, status, garageId, printVersion);
};

export const generateInvoiceFatturaPaXml = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.generateInvoiceFatturaPaXml(id, garageId);
};

export const addInvoiceEvent = (invoiceId, invoiceStatus) => (dispatch, getState) => {
  let eventName;
  switch (invoiceStatus) {
    case 'invoice':
      eventName = INVOICE_EVENTS.PRINTED_INVOICE;
      break;
    case 'draft':
      eventName = INVOICE_EVENTS.PRINTED_DRAFT;
      break;
    case 'internal':
      eventName = INVOICE_EVENTS.PRINTED_INTERNAL_ORDER;
      break;
    case 'preview':
      eventName = INVOICE_EVENTS.PRINTED_PREVIEW;
      break;
    case 'reminder':
      eventName = INVOICE_EVENTS.PRINTED_PAYMENT_REMINDER;
      break;
    case 'demandLetter1':
      eventName = INVOICE_EVENTS.PRINTED_DEMAND_LETTER1;
      break;
    case 'demandLetter2':
      eventName = INVOICE_EVENTS.PRINTED_DEMAND_LETTER2;
      break;
    case 'demandLetter3':
      eventName = INVOICE_EVENTS.PRINTED_DEMAND_LETTER3;
      break;
    default:
      return null;
  }

  return api.addInvoiceEvent(invoiceId, fromUser.getCurrentGarageId(getState()), eventName)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const editInvoice = () => (dispatch, getState) => {
  dispatch({ type: EDIT_INVOICE });
};

export const copyIntoDraft = (invoiceId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.copyIntoDraft(invoiceId, garageId)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const copyInvoiceIntoOffer = (invoiceId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.copyInvoiceIntoOffer(invoiceId, garageId)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const cancelMyPosPayment = (myposUid, invoiceId = null) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.cancelMyPosPayment(myposUid, garageId)
    .then((payload) => dispatch({
      type: UPDATE_INVOICE,
      payload: (payload.invoice || {}),
      status: payload.status,
      error: payload.error,
    }));
};

export const checkMyPosPayment = (myposUid, invoiceId = null) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.checkMyPosPayment(myposUid, garageId)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const restoreInvoice = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.restoreInvoice(currentGarageId, data)
    .then((payload) => dispatch({ type: RESTORE_INVOICE, data, payload }));
};

export const generateAssignmentDeclarationPdf = (id, caseOfDamage) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.generateAssignmentDeclarationPdf(garageId, id, caseOfDamage)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};

export const updateInvoiceNotesAndTasks = (invoiceId, notesAndTasks) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.updateInvoiceNotesAndTasks(garageId, invoiceId, notesAndTasks)
    .then((payload) => dispatch({ type: UPDATE_INVOICE, payload }));
};
